import React from 'react'

import ConceptikSVG from '../../../components/Shared/svg/ConceptikLogo'
import SocialShareSection from '../SocialShareSection/SocialShareSection'
import {
  PageWrapper,
  PageTitleWrapper,
  PageTitle,
  EyeBackground,
  PageTitleText,
  MessageWrapper,
  MessageTitle,
  MessageRow,
  MessageCol,
  MessageText,
  ThankYouWrapper,
  ThankYouContainer,
  ThankYouText,
  ConceptikWrapper,
  ConceptikContainer,
  ConceptikCol,
  ConceptikTitle,
  ConceptikLogoLink,
  ConceptikButton,
  LineSeparator,
  Container,
  PageName
} from './styled'

import EyeOpenedNoBg from '../../../images/eye-opened-main-page.svg'

const AboutPageWrapper = () => {
  return (
    <PageWrapper>
      <Container>
        <PageName> About</PageName>
        <PageTitleWrapper>
          <PageTitle>
            Our Impact
            <EyeBackground src={EyeOpenedNoBg} />
          </PageTitle>
          <PageTitleText>
            Together we can take action to stop this escalating environmental
            catastrophe and protect the nature and all human race for the
            future.
          </PageTitleText>
        </PageTitleWrapper>

        <MessageWrapper>
          <MessageTitle>Unite for bold climate action</MessageTitle>
          <MessageRow>
            <MessageCol>
              <MessageText>
                Climate change is happening. It’s not a lie, but a reality that
                strikes with the weight of a hammer on every aspect of the
                entire planet. Like an avalanche that affects every life and
                sweeps away everything behind, including us. The changes are
                happening at any moment, every minute. Some are instantaneous
                and hit us like a hurricane. While others last much more than
                one human life. But they are happening and causing more and more
                damages. That’s why we must open our eyes and take the necessary
                actions. It is our responsibility to take care of the planet as
                we take care of our homes. It is our big home after all, without
                which we cannot exist, but continue to destroy without batting
                an eye.
              </MessageText>
            </MessageCol>
            <MessageCol>
              <MessageText>
                Human activity is considered the number one cause of the global
                climate change which is the greatest challenge the human race
                has ever faced in history. And the scientists fear that the
                outcome cannot be good without immediate actions to reduce the
                human impact on the environment. That is why we at Conceptic
                believe that we should be responsible for the changes that are
                taking place and try to reduce their impact in all possible
                ways. Every action matters. Even every little talk about it.
                People should be aware of this issues and their ability to
                direct the outcome to change. After all we are trying to open
                eyes. Let’s spread the idea.
              </MessageText>
            </MessageCol>
          </MessageRow>
        </MessageWrapper>
      </Container>

      <ThankYouWrapper>
        <ThankYouContainer>
          <ThankYouText>Thank You!</ThankYouText>
        </ThankYouContainer>
      </ThankYouWrapper>

      <ConceptikWrapper>
        <ConceptikContainer>
          <ConceptikCol>
            <ConceptikTitle>Visit our website for more projects</ConceptikTitle>
            <ConceptikLogoLink href='https://conceptik.com/' target='_blank'>
              <ConceptikSVG />
            </ConceptikLogoLink>
          </ConceptikCol>
          <ConceptikCol>
            <ConceptikButton href='https://conceptik.com/' target='_blank'>
              Visit our website
            </ConceptikButton>
          </ConceptikCol>
        </ConceptikContainer>
      </ConceptikWrapper>

      <LineSeparator />

      <SocialShareSection />
    </PageWrapper>
  )
}

export default AboutPageWrapper
