import React from 'react'

import MainLayout from '../components/LayoutsRelated/Layouts/MainLayout'
import AboutPageWrapper from '../components/PageRelated/AboutPageWrapper/AboutPageWrapper'

const About = () => (
  <MainLayout isPage>
    <AboutPageWrapper />
  </MainLayout>
)
export default About
