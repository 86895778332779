import styled, { keyframes } from 'styled-components'

import {
  PageWrapperStyle,
  PageTitleWrapperStyle,
  PageTitleStyle,
  buttonStyle,
  PageNameComponent
} from '../../../styled/pageStyle'

import { siteBreakPoints, colors } from '../../../consts/styleConsts'
const { small } = siteBreakPoints
const { grey, darkGrey2 } = colors.grey
const { dark3 } = colors.background
const { primary } = colors

const container = 61.81
const width1400 = 1400
const width1150 = 1150

const FadeInLeft = keyframes`
  0% {opacity: 0; transform: translateX(-30px)}
  50% {transform: translateX(0)}
  100% {opacity: 1; transform: translateX(0)}
`
const FadeInBottom = keyframes`
  0% {opacity: 0; transform: translateY(30px)}
  50% {transform: translateY(0)}
  100% {opacity: 1; transform: translateY(0)}
`
const Scale = keyframes`
  0% {opacity: 0; transform: translate(25%, -50%) scale(0)}
  60% {opacity: 0; transform: translate(25%, -50%) scale(0.9)}
  100% {opacity: 0.13; transform: translate(25%, -50%) scale(1)}
`

export const PageWrapper = styled(PageWrapperStyle)``
export const Container = styled.div`
  width: ${container}rem;
  margin: 0 auto;
  @media screen and (max-width: ${width1400}px) {
    width: 100%;
  }
`
export const PageName = styled(PageNameComponent)`
  padding-left: 6.36rem;
  opacity: 0;
  animation: ${FadeInLeft} 1.5s ease forwards 0.2s;
  @media screen and (max-width: ${width1150}px) {
    padding-left: 3rem;
  }
`
export const PageTitleWrapper = styled(PageTitleWrapperStyle)`
  padding: 0 6.36rem;
  @media screen and (max-width: ${width1150}px) {
    padding: 0 3rem;
  }
`
export const PageTitle = styled(PageTitleStyle)`
  margin-bottom: 1.81rem;
  opacity: 0;
  animation: ${FadeInLeft} 1.5s ease forwards 0.4s;
  @media screen and (max-width: ${small}px) {
    margin-bottom: 1.25rem;
  }
`
export const EyeBackground = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  width: 34.09rem;
  opacity: 0;
  animation: ${Scale} 1.6s ease forwards;
`
export const PageTitleText = styled.span`
  color: ${darkGrey2};
  font-family: Plex-Light;
  font-size: 1.43rem;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 2rem;
  position: relative;
  opacity: 0;
  display: block;
  animation: ${FadeInLeft} 1.5s ease forwards 0.6s;
  @media screen and (max-width: ${small}px) {
    font-size: 1.12rem;
    line-height: 1.43rem;
  }
`
export const MessageWrapper = styled.div`
  margin: 0px auto;
  margin-top: 11.36rem;
  @media screen and (max-width: ${width1400}px) {
    padding: 0 6.36rem;
    margin-top: 4.37rem;
  }
  @media screen and (max-width: ${width1150}px) {
    padding: 0 3rem;
  }
`
export const MessageTitle = styled.span`
  color: ${grey};
  font-family: Plex-Bold;
  font-size: 1.54rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 2rem;
  position: relative;
  display: block;
  opacity: 0;
  animation: ${FadeInBottom} 1.5s ease forwards 0.8s;
  @media screen and (max-width: ${small}px) {
    font-size: 1.37rem;
  }
`
export const MessageRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  position: relative;
  opacity: 0;
  animation: ${FadeInBottom} 1.5s ease forwards 1s;
  @media screen and (max-width: ${width1400}px) {
    flex-direction: column;
  }
`
export const MessageCol = styled.div`
  max-width: 29rem;
  @media screen and (max-width: ${width1400}px) {
    margin-top: 0.93rem;
    max-width: 100%;
  }
`
export const MessageText = styled.span`
  color: ${grey};
  font-family: Plex-Light;
  font-size: 0.81rem;
  line-height: 1.45rebm;
  font-weight: 300;
  letter-spacing: 0;
  @media screen and (max-width: ${small}px) {
    font-size: 0.87rem;
    line-height: 1.5rem;
  }
`
export const ThankYouWrapper = styled.div`
  margin-top: 5.9rem;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: ${small}px) {
    margin-top: 4.12rem;
  }
`
export const ThankYouContainer = styled.div`
  padding-bottom: 1.13rem;
  border-bottom: 2px solid #4b7e9b;
  width: 33.33%;
  @media screen and (max-width: ${width1150}px) {
    width: 45%;
  }
  @media screen and (max-width: ${small}px) {
    padding-bottom: 1.25rem;
    width: 60%;
  }
`
export const ThankYouText = styled.span`
  color: ${grey};
  font-family: Plex-Bold;
  font-size: 2.72rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 3.5rem;
  text-transform: uppercase;
  @media screen and (max-width: ${small}px) {
    font-size: 1.75rem;
    line-height: 2.31rem;
  }
`
export const ConceptikWrapper = styled.div`
  background: ${dark3};
  margin: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media screen and (max-width: ${small}px) {
    margin: 3.75rem 0;
  }
`
export const ConceptikContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 4.36rem;
  margin-bottom: 5.22rem;
  width: ${container}rem;
  @media screen and (max-width: ${width1400}px) {
    width: 100%;
    padding: 0 6.36rem;
  }
  @media screen and (max-width: ${width1150}px) {
    padding: 0 3rem;
    flex-direction: column;
    align-items: center;
  }
`
export const ConceptikCol = styled.div``
export const ConceptikTitle = styled.span`
  color: ${grey};
  font-family: Plex-Bold;
  font-size: 1.54rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 2rem;
  text-align: center;
  display: block;
  @media screen and (max-width: ${small}px) {
    font-size: 1.37rem;
  }
`
export const ConceptikLogoLink = styled.a`
  width: 12.54rem;
  display: block;
  margin-top: 1.81rem;
  & > svg {
    width: 100%;
  }
  @media screen and (max-width: ${width1150}px) {
    margin: 0 auto;
    margin-bottom: 5.62rem;
    margin-top: 1.81rem;
  }
  @media screen and (max-width: ${small}px) {
    margin-bottom: 5.62rem;
    width: 12.56rem;
  }
`

export const ConceptikButton = styled.a`
  ${buttonStyle};
  position: relative;
  bottom: 1.87rem;
`
export const LineSeparator = styled.div`
  height: 2px;
  width: 100%;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 90%;
    height: 100%;
    background: ${primary};
  }
`
